import {get, post} from "./request.js";

// 查询企业基本信息
export const companyInfo = (data) => get("/base/companyInfo", data);
// 注册登录
export const register = (data) => post("/user/register", data);
// 注册发送验证码
export const registerSendMsg = (data) => get("/sendMsg/register", data);
// 忘记密码
export const forgetPassword = (data) => post("/user/forgetPassword", data);
// 忘记密码发送验证码
export const forgetPasswordSendMsg = (data) =>
  get("/sendMsg/forgetPassword", data);
// 获取banner图，传入参数：type（1PC端2移动端3管理后台登录页面封面图4PC登录页面图片5企业logo）
export const bannerList = (data) => get("/base/bannerList", data);
// 登录
export const login = (data) => post("/user/login", data);
//获取用户信息
export const getUserInfo = (data) => get("/user/getUserInfo", data);
//获取首页文章列表
export const getHomeArticleList = (data) =>
  get("/base/getHomeArticleList", data);
//退出登录
export const logout = (data) => post("/user/logout", data);
//修改用户资料
export const updateUserInfo = (data) => post("/user/updateUserInfo", data);
//查询计划
export const planList = (data) => get("/plan/search", data);
//省列表
export const getProvinceList = (data) => get("/base/getProvinceList", data);
//城市列表
export const getCityList = (data) => get("/base/getCityList", data);
// 区域列表
export const getAreaList = (data) => get("/base/getAreaList", data);
// 查询分类
export const searchCategory = (data) => get("/plan/searchCategory", data);
// 资讯分类
export const articleCategoryList = (data) =>
  get("/base/articleCategoryList", data);
// 资讯列表
export const articleList = (data) => get("/base/articleList", data);
// 资讯详情
export const articleInfo = (data) => get(`/base/articleInfo/${data.articleId}`);
// 表格信息
export const getStudentInfo = (data) => get(`/applyexam/getStudentInfo`, data);
// 报考须知
export const getExamExplan = (data) => get(`applyexam/getExamExplan`, data);
// 计划详情
export const getPlanInfo = (data) => get(`/plan/getPlanInfo`, data);
// 提交申报表格
export const confirmTable = (data) => post(`/applyexam/confirm`, data);
// 下载表格
export const downloadFileTable = (data) => get(`/applyexam/downloadFile`, data);
// 上传表格
export const uploadFileTable = (data) => post(`/applyexam/uploadFile`, data);
// 提交附件
export const confirmProfile = (data) => post(`/applyexam/confirmProfile`, data);
// 提交报名
export const signUp = (data) => get(`/applyexam/signUp`, data);
// 提交报名
export const getPaymentConfig = (data) =>
  get(`/payment/getPaymentConfig`, data);
// 提交报名
export const uploadPaymentImage = (data) =>
  post(`/order/uploadPaymentImage`, data);
// 生成订单
export const generateOrder = (data) => get(`/order/generateOrder`, data);
// 预支付
export const prepay = (data) => post(`/payment/prepay`, data);
// 微信支付回调
export const getOrderStatus = (data) => get(`/order/getOrderStatus`, data);
// 参加报考的计划
export const queryAchievementList = (data) =>
  get(`/plan/queryAchievementList`, data);
// 成绩查询
export const queryAchievementHistory = (data) =>
  get(`/plan/queryAchievementHistory`, data);
// 证书查询
export const queryCertificateList = (data) =>
  get(`/plan/queryCertificateList`, data);
// 开票查询
export const queryPlanListByInvoice = (data) =>
  get(`/plan/queryPlanListByInvoice`, data);
// 申请开票
export const applyInvoicing = (data) => post(`/order/applyInvoicing`, data);
// 申请开票
export const getAboutus = (data) => get(`/base/getAboutus`, data);
// 修改密码
export const modifyPassword = (data) => post(`/user/modifyPassword`, data);
// 编排状态
export const getCertStatus = (data) => get(`/applyexam/getCertStatus`, data);
// 获取机构
export const getPartnerList = (data) => get(`/plan/getPartnerList/${data.planId}`);
// 编辑状态
export const updatePlanStudentStatus = (data) =>
  get(`/user/updatePlanStudentStatus/${data.planStudentId}`);
//省列表
export const getProvinceListByCompany = (data) =>
  get(`/base/getProvinceListByCompany`, data);
//城市列表
export const getCityListByCompany = (data) =>
  get("/base/getCityListByCompany", data);
// 区域列表
export const getAreaListByCompany = (data) =>
  get("/base/getAreaListByCompany", data);
