<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import {getUserInfo} from "@/util/api";
export default {
  created() {
      const vm = this;
      document.addEventListener("visibilitychange", function () {
        if (vm.$store.state.Authorization != localStorage.getItem("Authorization")) {
          location.reload();
        }
      });
      this.$router.beforeEach((to, from, next) => {
        if(to.name !== '注册'&&to.name!=='登录'&&to.name!=='忘记密码'){
          let uid = localStorage.getItem('uid')
          getUserInfo().then(res=>{
            if(res.data.id == uid){
              this.$store.commit('changeUserInfo',res.data)
            }
          })
        }
        next();
      });
    },
};
</script>
<style lang="scss">
html {
  min-width: 1200px;
}
* {
  margin: 0;
  padding: 0;
}
.font16 {
  font-size: 16px;
}
.font18 {
  font-size: 18px;
}
.font14 {
  font-size: 14px;
}
.font12 {
  font-size: 12px;
}
.font26 {
  font-size: 28px;
}
.font28 {
  font-size: 28px;
}
.fontw {
  font-weight: bold;
}
.color33 {
  color: #333;
}
.color66 {
  color: #666;
}
.color99 {
  color: #999;
}
.colorff {
  color: #fff;
}
.margin100 {
  margin-bottom: 100px;
}
.padding100 {
  padding-bottom: 100px;
}
.skyblue {
  color: #5586e1;
}
.danger {
  color: #ce2020;
}
.success {
  color: #2fa74d;
}
.el-dialog__header {
  text-align: center;
  padding: 20px 0 !important;
  border-bottom: 1px solid #cccccc;
}
.box_title {
  padding: 40px 40px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}
</style>
