import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    companyInfo: null,
    Authorization: localStorage.getItem("Authorization")
      ? localStorage.getItem("Authorization")
      : "",
    userInfo: null,
    showBaseInfo: false,
    isChange: false,
  },

  mutations: {
    // 修改token，并将token存入localStorage
    changeLogin(state, user) {
      state.Authorization = user.Authorization;
      localStorage.setItem("Authorization", user.Authorization);
      localStorage.setItem("token", user.Authorization);
    },
    changeUserInfo(state, user) {
      state.userInfo = user;
    },
    changeShowInfo(state, val) {
      state.showBaseInfo = val;
    },
    changeisChange(state, val) {
      state.isChange = val;
    },
  },
  actions: {
  },
  modules: {},
});
