import Vue from "vue";
import VueRouter from "vue-router";
import index from "../views/index.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "index",
    component: index,
    children: [
      {
        path: "/" ,
        name: "首页",
        component: () => import("../views/index/index.vue"),
      },
      {
        path: "/signCenter",
        name: "报考中心",
        component: () => import("../views/signCenter/signCenter.vue"),
      },
      {
        path: "/aboutUs",
        name: "关于我们",
        component: () => import("../views/aboutUs/aboutUs.vue"),
      },
      {
        path: "/process",
        name: "报名流程",
        component: () => import("../views/registration/process.vue"),
      },
      {
        path: "/reForInfo",
        name: "完善资料",
        component: () => import("../views/registration/reForInfo.vue"),
      },
      {
        path: "/newsCenter",
        name: "新闻中心",
        component: () => import("../views/news/index.vue"),
      },
      {
        path: "/newsCenter/newsDetails",
        name: "详情",
        component: () => import("../views/news/newsDetails.vue"),
      },
      {
        path: "/user",
        name: "个人中心",
        component: () => import("../views/user/index.vue"),
        redirect: {path: "/user/myInfo"},
        children: [
          {
            path: "/user/mySign",
            name: "我的报考",
            component: () => import("../views/user/mySign.vue"),
          },
          {
            path: "/user/achievement",
            name: "我的成绩",
            component: () => import("../views/user/achievement.vue"),
          },
          {
            path: "/user/certificate",
            name: "我的证书",
            component: () => import("../views/user/certificate.vue"),
          },
          {
            path: "/user/myInvoice",
            name: "我的发票",
            component: () => import("../views/user/myInvoice.vue"),
          },
          {
            path: "/user/myInfo",
            name: "我的信息",
            component: () => import("../views/user/myInfo.vue"),
          },
          {
            path: "/user/changePwd",
            name: "修改密码",
            component: () => import("../views/user/changePwd.vue"),
          },
        ],
      },
      {
        path: "/user/myInvoice/openInvoice",
        name: "",
        component: () => import("../views/user/openInvoice.vue"),
      },
    ],
  },
  {
    path: "/guide",
    name: "登录",
    component: () => import("../views/login/index.vue"),
    redirect: {
      path: "/guide/login",
    },
    children: [
      {
        path: "/login",
        name: "登录",
        component: () => import("../views/login/login.vue"),
      },
      {
        path: "/guide/login",
        name: "登录",
        component: () => import("../views/login/login.vue"),
      },
      {
        path: "/guide/resetpwd",
        name: "忘记密码",
        component: () => import("../views/login/resetPwd.vue"),
      },
      {
        path: "/guide/register",
        name: "注册",
        component: () => import("../views/login/register.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

export default router;
