/**axios封装
 * 请求拦截、相应拦截、错误统一处理
 */
import axios from "axios";
import QS from "qs";
import {Message} from "element-ui";
import store from "../store/index";
import router from "../router";

// 环境的切换
if (process.env.NODE_ENV == "development") {
  axios.defaults.baseURL = "/webApi";
  // axios.defaults.baseURL = "https://qycsb.gz-jiayou.com/carryEnd";
  // axios.defaults.baseURL = "https://hrss.xyloves.cn/carryEnd";
  // axios.defaults.baseURL = "http://192.168.3.2:88/carryEnd";
} else if (process.env.NODE_ENV == "debug") {
  axios.defaults.baseURL = "";
} else if (process.env.NODE_ENV == "production") {
  // axios.defaults.baseURL = "https://exam.career99.cn:8082/carryEnd";
  // axios.defaults.baseURL = "https://exam.gz-jiayou.com/carryEnd";
  axios.defaults.baseURL = "/webApi";
}

// 请求超时时间
axios.defaults.timeout = 18000;

// post请求头
axios.defaults.headers.post["Content-Type"] =
  "application/json;charset=utf-8" || "multipart/form-data";

// 请求拦截器
axios.interceptors.request.use(
  (config) => {
    // 每次发送请求之前判断是否存在token，如果存在，则统一在http请求的header都加上token，不用每次请求都手动添加了
    // 即使本地存在token，也有可能token是过期的，所以在响应拦截器中要对返回状态进行判断
    if (localStorage.getItem("Authorization")) {
      config.headers.token = localStorage.getItem("Authorization");
    }
    //    const token = store.state.token;
    //    token && (config.headers.token = token);
    //    console.log(token)
    return config;
  },
  (error) => {
    return Promise.error(error);
  }
);

// 响应拦截器
axios.interceptors.response.use(
  (response) => {
    // console.log(response,'request')
    if (response.status === 200) {
      if (response.data.code == 500 || response.data.code == 504) {
        Message.warning(response.data.msg || "未知异常，请联系管理员");
        return Promise.reject(response);
      } else if (response.data.code == 1003) {
        store.state.Authorization = "";
        localStorage.removeItem("Authorization");
        router.replace({
          path: "/guide",
        });
      } else return Promise.resolve(response);
    } else {
      Message.warning(response.data.msg || "网络请求失败，请重试");
      return Promise.reject(response);
    }
  },
  // 服务器状态码不是200的情况
  (error) => {
    if (error.response.status) {
      switch (error.response.status) {
        // 401: 未登录
        // 未登录则跳转登录页面，并携带当前页面的路径
        // 在登录成功后返回当前页面，这一步需要在登录页操作。
        case 401:
          router.replace({
            path: "/login",
            query: {
              redirect: router.currentRoute.fullPath,
            },
          });
          break;
        // 403 token过期
        // 登录过期对用户进行提示
        // 清除本地token和清空vuex中token对象
        // 跳转登录页面
        case 403:
          Toast({
            message: "登录过期，请重新登录",
            duration: 1000,
            forbidClick: true,
          });
          // 清除token
          localStorage.removeItem("token");
          store.commit("loginSuccess", null);
          // 跳转登录页面，并将要浏览的页面fullPath传过去，登录成功后跳转需要访问的页面
          setTimeout(() => {
            router.replace({
              path: "/login",
              query: {
                redirect: router.currentRoute.fullPath,
              },
            });
          }, 1000);
          break;
        // 404请求不存在
        case 404:
          Toast({
            message: "网络请求不存在",
            duration: 1500,
            forbidClick: true,
          });
          break;
        // 其他错误，直接抛出错误提示
        default:
          Toast({
            message: error.response.data.message,
            duration: 1500,
            forbidClick: true,
          });
      }
      return Promise.reject(error.response);
    }
  }
);
/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function get(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        params: params,
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.data);
      });
  });
}
/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function post(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .post(url, params)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.data);
      });
  });
}

export function downBlobFile(url, query, fileName) {
  return axios({
    url: url,
    method: "get",
    responseType: "blob",
    params: query,
  }).then((response) => {
    // 处理返回的文件流
    const blob = response.data;
    if (blob && blob.size === 0) {
      Notification.error("内容为空，无法下载");
      return;
    }
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    window.setTimeout(function () {
      URL.revokeObjectURL(blob);
      document.body.removeChild(link);
    }, 0);
  });
}
