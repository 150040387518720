<template>
  <div>
    <div class="top">
      <div class="comtent">
        <div class="left">
          <div class="logo">
            <img :src="logo" alt="" v-if="logo" />
          </div>
          <!-- 导航 -->
          <div>
            <!-- router -->
            <el-menu
              mode="horizontal"
              background-color="#ffffff"
              text-color="#333333"
              active-text-color="#2B68D9"
              border-bottom="none"
              :default-active="path"
              router
            >
              <el-menu-item
                v-for="item in nav_menu_data"
                :index="item.path"
                :key="item.id"
              >
                <span
                  v-if="item.id == 6"
                  @click="toCenter"
                  style="
                    display: inline-block;
                    height: 100%;
                    width: 100%;
                    line-height: 62px;
                  "
                >
                  {{ item.title }}</span
                >
                <span v-else> {{ item.title }}</span>
              </el-menu-item>
            </el-menu>
          </div>
        </div>

        <!-- 个人中心 -->
        <div class="right" v-if="userInfo">
          <el-dropdown trigger="click">
            <div class="header_box">
              <img
                :src="
                  userInfo.picUrl
                    ? userInfo.picUrl
                    : require('../assets/normal_header.jpg')
                "
                alt=""
              />
              <p>{{ userInfo.name ? userInfo.name : userInfo.mobile }}</p>
            </div>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>
                <p @click="toUser">个人中心</p>
              </el-dropdown-item>
              <el-dropdown-item>
                <p @click="toLoginOut">退出登录</p>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div class="right" v-else>
          <span class="font16 color33" @click="toLogn">登录 / 注册</span>
        </div>
      </div>
    </div>
    <router-view></router-view>

    <!-- 底部 -->
    <template v-if="hostname != 'kone.gz-jiayou.com'">
      <foot></foot>
    </template>
    <div v-if="showBaseInfo">
      <el-dialog
        title="完善个人信息"
        @close="closeDialog"
        :close-on-click-modal="false"
        :visible="showBaseInfo"
        width="850px"
      >
        <div class="my_form">
          <base-info></base-info>
        </div>
      </el-dialog>
    </div>
    <passWord ref="passTrue" />
  </div>
</template>

<script>
import passWord from '@/components/pass-word/index'
import baseInfo from "@/components/baseInfo.vue";
import {getUserInfo, logout, companyInfo} from "@/util/api";
import foot from "@/components/foot.vue";
import {mapMutations, mapState} from "vuex";
export default {
  components: {
    foot,
    baseInfo,
    passWord
  },
  computed: {
    ...mapState(["userInfo", "showBaseInfo"]),
  },
  data() {
    return {
      path: "/",
      nav_menu_data: [
        {
          id: 1,
          title: "首页",
          path: "/",
        },
        {
          id: 2,
          title: "报考中心",
          path: "/signCenter",
        },
        {
          id: 5,
          title: "我的报考",
          path: "/user/mySign",
        },
        {
          id: 3,
          title: "新闻动态",
          path: "/newsCenter",
        },

        {
          id: 4,
          title: "关于我们",
          path: "/aboutUs",
        },
      ],
      token: null,
      logo: null,
      companyInfo: {},
      user: {},
      hostname:window.location.hostname
    };
  },
  watch: {
    // 监测路由变化,只要变化了就调用获取路由参数方法将数据存储本组件即可
    $route: "onRouteChanged",
  },
  created() {
    this.onRouteChanged();
    this.token = window.localStorage.getItem("Authorization");
    let uid = localStorage.getItem('uid')
    if (this.token) {
      getUserInfo().then((res) => {
        this.user = res.data;
        window.localStorage.setItem("companyId", res.data.companyId);
        window.localStorage.setItem("company_id", res.data.companyId);
        window.localStorage.setItem("uid", res.data.id);
        if (res.data.isFull != 1) {
          this.changeShowInfo(true);
        }
        if(res.data.passwordStatus == 0) {
          this.$refs.passTrue.showChangePwd = true
        }
        // if (res.data.sex != null) {
        //   res.data.sex = Number(res.data.sex);
        // }
        // this.$router.beforeEach((to, from, next) => {
        //   let uid = localStorage.getItem('uid')
        //   if(res.data.id == uid){
        //       this.$store.commit('changeUserInfo',res.data)
        //   }
        //   next()
        // });
       
        this.changeUserInfo(res.data);
      });
    }
  },
  mounted() {
    companyInfo({
      domainUrl: window.location.hostname,
    })
      .then((res) => {
        this.bg = res.data.loginPicList;
        this.logo = res.data.logo;
        this.companyInfo = res.data;
        if (this.companyInfo.oldDomainUrl) {
          this.nav_menu_data = [
            {
              id: 1,
              title: "首页",
              path: "/",
            },
            {
              id: 2,
              title: "报考中心",
              path: "/signCenter",
            },
            {
              id: 5,
              title: "我的报考",
              path: "/user/mySign",
            },
            {
              id: 6,
              title: "练习中心",
            },
            {
              id: 3,
              title: "新闻动态",
              path: "/newsCenter",
            },

            {
              id: 4,
              title: "关于我们",
              path: "/aboutUs",
            },
          ];
        }
      })
      .catch((err) => {});
  },
  methods: {
    ...mapMutations(["changeUserInfo", "changeShowInfo"]),
    onRouteChanged() {
      let that = this;
      that.path = that.$route.path;
    },
    toCenter() {
      console.log(this.companyInfo,'aaaaaaaaa')
      window.open(
        `https://${this.companyInfo.oldDomainUrl}/train_pc/#/personalcenter/myclass`
      );
    },
    closeDialog() {
      this.changeShowInfo(false);
      getUserInfo().then((res) => {
        this.user = res.data;
        this.changeUserInfo(res.data);
      });
    },
    toLogn() {
      this.$router.push("/guide");
      // window.location.href = "/my_list.xlsx";
    },
    toUser() {
      this.$router.push("/user");
    },
    toLoginOut() {
      let that = this;
      that
        .$confirm("是否确定退出当前账号", "退出账号", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          logout().then((res) => {
            window.localStorage.removeItem("token");
            window.localStorage.removeItem("companyId");
            // window.localStorage.removeItem("uid");
            that.$message.success("退出登录");
            that.$router.replace("/guide");
          });
        })
        .catch(() => {});
    },
  },
};
</script>

<style scoped>
.top >>> .el-menu.el-menu--horizontal {
  /* border: none; */
}
.top >>> .is-active {
  border-bottom: none !important;
  border-top: 2px solid #5586e0;
  background-color: #f5f5f5 !important;
}
.top >>> .el-menu-item {
  border-bottom: none;
  border-top: 2px solid transparen;
  height: 79px;
}
</style>
<style lang="scss" scoped>
.my_form {
  ::v-deep .el-dialog__body {
    padding: 0 20px 20px 20px!important;
  }
}

.top {
  background-color: #fff;
  height: 80px;
  width: 100%;
  .comtent {
    width: 1200px;
    margin: 0 auto;
    // background: red;
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: space-between;
    .left {
      display: flex;
      align-items: center;
    }
    .logo {
      width: 250px;
      height: 60px;
      margin-right: 100px;
      // background-color: #5586e0;
      img {
        height: 100%;
      }
    }
    .right {
      span {
        cursor: pointer;
        transition: all 0.3s;
      }
      span:hover {
        color: #5586e0;
      }
    }
  }
}
.header_box {
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
  p {
    font-size: 14px;
    font-weight: bold;
    margin-top: 5px;
    color: #5586e0;
  }
}
</style>
