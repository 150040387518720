var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-form',{ref:"ruleForm",staticClass:"demo-ruleForm",attrs:{"model":_vm.userInfo,"label-width":"120px"}},[_c('div',{staticClass:"sub_title"},[_c('span',{staticStyle:{"color":"red"}},[_vm._v("'证件号码'")]),_vm._v("如有X字符,请使用"),_c('span',{staticStyle:{"color":"red"}},[_vm._v("大写英文字母X")])]),_c('el-form-item',{attrs:{"label":"姓名","required":""}},[_c('el-col',{attrs:{"span":9}},[_c('el-form-item',{attrs:{"prop":"name","rules":[{required: true, message: '请输入姓名', trigger: 'blur'}]}},[_c('el-input',{model:{value:(_vm.userInfo.name),callback:function ($$v) {_vm.$set(_vm.userInfo, "name", $$v)},expression:"userInfo.name"}})],1)],1)],1),_c('el-form-item',{attrs:{"label":"手机","required":""}},[_c('el-col',{attrs:{"span":9}},[_c('el-form-item',{attrs:{"prop":"mobile","rules":[{required: true, message: '请输入手机号', trigger: 'blur'}]}},[_c('el-input',{model:{value:(_vm.userInfo.mobile),callback:function ($$v) {_vm.$set(_vm.userInfo, "mobile", $$v)},expression:"userInfo.mobile"}})],1)],1)],1),_c('el-form-item',{attrs:{"label":"证件类型","required":""}},[_c('el-col',{attrs:{"span":9}},[_c('el-form-item',{attrs:{"prop":"idCardType","rules":[
          {
            required: true,
            message: '请选择证件类型',
            trigger: 'change',
          } ]}},[_c('el-select',{staticStyle:{"width":"100%"},on:{"change":_vm.cardtype},model:{value:(_vm.userInfo.idCardType),callback:function ($$v) {_vm.$set(_vm.userInfo, "idCardType", $$v)},expression:"userInfo.idCardType"}},_vm._l((_vm.cardTypeList),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],1)],1),_c('el-col',{attrs:{"span":13}},[_c('el-form-item',{attrs:{"label":"证件号码","prop":"idCard","rules":[
          {
            required: true,
            message: '请输入证件号码',
            trigger: 'blur',
          } ]}},[_c('el-input',{attrs:{"type":"text"},on:{"blur":_vm.getAgeSexBirthdayByIdcard},model:{value:(_vm.userInfo.idCard),callback:function ($$v) {_vm.$set(_vm.userInfo, "idCard", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"userInfo.idCard"}})],1)],1)],1),_c('el-form-item',{attrs:{"label":"出生日期","required":""}},[_c('el-col',{attrs:{"span":9}},[_c('el-form-item',{attrs:{"prop":"birthday","rules":[
          {
            required: true,
            message: '请选择出生日期',
            trigger: 'change',
          } ]}},[_c('el-date-picker',{staticStyle:{"width":"100%"},attrs:{"disabled":_vm.isDisable,"type":"date","format":"yyyy-MM-dd","value-format":"yyyy-MM-dd","placeholder":"请选择出生日期"},model:{value:(_vm.userInfo.birthday),callback:function ($$v) {_vm.$set(_vm.userInfo, "birthday", $$v)},expression:"userInfo.birthday"}})],1)],1),_c('el-col',{attrs:{"span":13}},[_c('el-form-item',{attrs:{"label":"性别","prop":"sex","rules":[
          {
            required: true,
            message: '请选择性别',
            trigger: 'change',
          } ]}},[_c('el-select',{staticStyle:{"width":"100%"},attrs:{"disabled":_vm.isDisable},model:{value:(_vm.userInfo.sex),callback:function ($$v) {_vm.$set(_vm.userInfo, "sex", $$v)},expression:"userInfo.sex"}},[_c('el-option',{attrs:{"label":"男","value":1}}),_c('el-option',{attrs:{"label":"女","value":2}}),_c('el-option',{attrs:{"label":"未知","value":0}})],1)],1)],1)],1),_c('el-form-item',{attrs:{"label":"民族","required":""}},[_c('el-col',{attrs:{"span":9}},[_c('el-form-item',{attrs:{"prop":"nationality","rules":[
          {
            required: true,
            message: '请选择民族',
            trigger: 'change',
          } ]}},[_c('el-select',{staticStyle:{"width":"100%"},model:{value:(_vm.userInfo.nationality),callback:function ($$v) {_vm.$set(_vm.userInfo, "nationality", $$v)},expression:"userInfo.nationality"}},_vm._l((_vm.nationalityList),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.label,"value":item.value}})}),1)],1)],1),_c('el-col',{attrs:{"span":13}},[_c('el-form-item',{attrs:{"prop":"workUnitName","label":"所在单位","rules":[
          {
            required: true,
            message: '请填写所在单位',
            trigger: 'blur',
          } ]}},[_c('el-input',{model:{value:(_vm.userInfo.workUnitName),callback:function ($$v) {_vm.$set(_vm.userInfo, "workUnitName", $$v)},expression:"userInfo.workUnitName"}})],1)],1)],1),_c('el-form-item',{attrs:{"label":"劳务关系","required":""}},[_c('el-col',{attrs:{"span":9}},[_c('el-form-item',{attrs:{"prop":"laborRelation","rules":[
          {
            required: true,
            message: '请选择劳务关系',
            trigger: 'change',
          } ]}},[_c('el-select',{staticStyle:{"width":"100%"},model:{value:(_vm.userInfo.laborRelation),callback:function ($$v) {_vm.$set(_vm.userInfo, "laborRelation", $$v)},expression:"userInfo.laborRelation"}},_vm._l((_vm.laborRelationList),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],1)],1),_c('el-col',{attrs:{"span":13}},[_c('el-form-item',{attrs:{"label":"工龄","prop":"workYear"}},[_c('el-input',{attrs:{"type":"text"},model:{value:(_vm.userInfo.workYear),callback:function ($$v) {_vm.$set(_vm.userInfo, "workYear", $$v)},expression:"userInfo.workYear"}})],1)],1)],1),_c('el-form-item',{attrs:{"label":"文化程度","required":""}},[_c('el-col',{attrs:{"span":9}},[_c('el-form-item',{attrs:{"prop":"education","rules":[
          {
            required: true,
            message: '请选择文化程度',
            trigger: 'change',
          } ]}},[_c('el-select',{staticStyle:{"width":"100%"},model:{value:(_vm.userInfo.education),callback:function ($$v) {_vm.$set(_vm.userInfo, "education", $$v)},expression:"userInfo.education"}},_vm._l((_vm.eductionList),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],1)],1),_c('el-col',{attrs:{"span":13}},[_c('el-form-item',{attrs:{"label":"考生来源","prop":"source","rules":[
          {
            required: true,
            message: '请选择考生来源',
            trigger: 'change',
          } ]}},[_c('el-select',{staticStyle:{"width":"100%"},model:{value:(_vm.userInfo.source),callback:function ($$v) {_vm.$set(_vm.userInfo, "source", $$v)},expression:"userInfo.source"}},_vm._l((_vm.mySourceList),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],1)],1)],1),_c('el-form-item',{attrs:{"label":"邮箱地址","required":""}},[_c('el-col',{attrs:{"span":9}},[_c('el-form-item',{attrs:{"prop":"email","rules":[
          {
            required: true,
            message: '请输入邮箱地址',
            trigger: 'blur',
          },
          {
            type: 'email',
            message: '请输入正确的邮箱地址',
            trigger: ['blur', 'change'],
          } ]}},[_c('el-input',{model:{value:(_vm.userInfo.email),callback:function ($$v) {_vm.$set(_vm.userInfo, "email", $$v)},expression:"userInfo.email"}})],1)],1)],1),_c('el-form-item',{attrs:{"label":"证书获取途径","required":""}},[_c('el-col',{attrs:{"span":9}},[_c('el-form-item',{attrs:{"prop":"gainCertType","rules":[
          {
            required: true,
            message: '请选择证书获取途径',
            trigger: 'change',
          } ]}},[_c('el-radio-group',{model:{value:(_vm.userInfo.gainCertType),callback:function ($$v) {_vm.$set(_vm.userInfo, "gainCertType", $$v)},expression:"userInfo.gainCertType"}},[_c('el-radio',{attrs:{"label":1}},[_vm._v("自取")]),_c('el-radio',{attrs:{"label":2}},[_vm._v("邮寄")])],1)],1)],1)],1),(_vm.userInfo.gainCertType == 2)?_c('div',[_c('el-form-item',{attrs:{"label":"收件人","required":""}},[_c('el-col',{attrs:{"span":9}},[_c('el-form-item',{attrs:{"prop":"addressee","rules":[
            {required: true, message: '请输入收件人', trigger: 'blur'} ]}},[_c('el-input',{model:{value:(_vm.userInfo.addressee),callback:function ($$v) {_vm.$set(_vm.userInfo, "addressee", $$v)},expression:"userInfo.addressee"}})],1)],1),_c('el-col',{attrs:{"span":13}},[_c('el-form-item',{attrs:{"label":"联系电话","prop":"contactPhone","rules":[
            {required: true, message: '联系电话', trigger: 'blur'},
            {
              pattern: /^1[3|5|7|8|9]\d{9}$/,
              message: '请输入正确的号码格式',
              trigger: 'change',
            } ]}},[_c('el-input',{model:{value:(_vm.userInfo.contactPhone),callback:function ($$v) {_vm.$set(_vm.userInfo, "contactPhone", $$v)},expression:"userInfo.contactPhone"}})],1)],1)],1),_c('el-form-item',{attrs:{"label":"详细地址","prop":"address","rules":[{required: true, message: '详细地址', trigger: 'blur'}],"required":""}},[_c('el-input',{staticStyle:{"width":"91%"},model:{value:(_vm.userInfo.address),callback:function ($$v) {_vm.$set(_vm.userInfo, "address", $$v)},expression:"userInfo.address"}})],1)],1):_vm._e(),_c('div',{staticClass:"my_btn"},[_c('el-button',{attrs:{"type":"info","plain":""},on:{"click":_vm.closeInfo}},[_vm._v("取消")]),_c('el-button',{staticClass:"wait",attrs:{"type":"primary"},on:{"click":function($event){return _vm.submitForm('ruleForm')}}},[_vm._v("确定")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }