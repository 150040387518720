<template>
  <el-dialog
    :show-close="false"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    custom-class="my_class"
    title="系统升级弱密码强制修改"
    :visible.sync="showChangePwd"
    width="600px"
    center
  >
    <div class="my_form">
      <el-form
        :model="ruleForm"
        status-icon
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="旧密码" prop="oldPass">
          <el-input
            type="password"
            v-model="ruleForm.oldPass"
            autocomplete="off"
            show-password
          ></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="pass">
          <el-input
            type="password"
            v-model="ruleForm.pass"
            autocomplete="off"
            show-password
          >
            <template v-if="ruleForm.pass" slot="append">{{
              classTyle
            }}</template>
          </el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="checkPass">
          <el-input
            type="password"
            v-model="ruleForm.checkPass"
            autocomplete="off"
            show-password
          ></el-input>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="isShowChangePwd" style="width: 120px"
        >退出系统</el-button
      >
      <el-button type="primary" @click="tochangePwd" style="width: 120px"
        >修改登录</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { modifyPassword,logout } from "@/util/api";
export default {
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        if (!this.validateString(value)) {
          callback(
            new Error("密码必须包含数字、大小写字母及符号，且长度不能小于8位")
          );
          this.classTyle = "低";
        } else {
          if (value.length < 8) {
            callback(
              new Error("密码必须包含数字、大小写字母及符号，且长度不能小于8位")
            );
            this.classTyle = "低";
          } else if (value.length < 13) {
            this.classTyle = "中";
            if (this.ruleForm.checkPass !== "") {
              this.$refs.ruleForm.validateField("checkPass");
            }
            callback();
          } else {
            this.classTyle = "高";
            if (this.ruleForm.checkPass !== "") {
              this.$refs.ruleForm.validateField("checkPass");
            }
            callback();
          }
        }
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.ruleForm.pass) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      showChangePwd: false,
      classTyle: "低",
      ruleForm: {},
      rules: {
        oldPass: [{ required: true, message: "请输入旧密码", trigger: "blur" }],
        pass: [{ required: true, validator: validatePass, trigger: "blur" }],
        checkPass: [
          { required: true, validator: validatePass2, trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    tochangePwd() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          modifyPassword({
            newPassword: this.ruleForm.pass,
            oldPassword: this.ruleForm.oldPass,
            domainUrl: window.location.hostname,
          }).then((res) => {
            if(res.code == 200){
              this.$message.success('修改成功')
              this.isShowChangePwd()
            }else{
              this.$message.warning(res.data.msg)
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
      
    },
    validateString(str) {
      // 正则表达式，分别检查四个条件
      const hasDigit = /.*\d.*/;
      const hasLowerCase = /.*[a-z].*/;
      const hasUpperCase = /.*[A-Z].*/;
      const hasSpecialChar = /.*[^\da-zA-Z].*/;

      // 检查每个条件是否满足，并计算满足条件的数量
      let count = 0;
      if (hasDigit.test(str)) count++;
      if (hasLowerCase.test(str)) count++;
      if (hasUpperCase.test(str)) count++;
      if (hasSpecialChar.test(str)) count++;
      // 检查是否至少满足三个条件
      return count >= 3;
    },
    isShowChangePwd() {
        logout().then((res) => {
          window.localStorage.removeItem("token");
          window.localStorage.removeItem("companyId");
          // window.localStorage.removeItem("uid");
          this.$message.success("退出登录");
          this.$router.replace("/guide");
        });
    },
  },
};
</script>

<style>
</style>